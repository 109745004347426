exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ButtonContentBlock__Button_button-block-wrapper{display:flex;align-items:center;justify-content:center;text-align:center}", ""]);

// exports
exports.locals = {
	"button-block-wrapper": "ButtonContentBlock__Button_button-block-wrapper",
	"buttonBlockWrapper": "ButtonContentBlock__Button_button-block-wrapper"
};