import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkButton } from 'sana/elements';

const Button = ({ model }) => {
  if (!model)
    return null;

  const { buttonText, link } = model;

  return (
    <div className={classNames(styles.buttonBlockWrapper, 'button-content-block')}>
      <LinkButton {...link} className="button-button-click">{buttonText}</LinkButton>
    </div>
  );
};

Button.propTypes = {
  model: PropTypes.shape({
    buttonText: PropTypes.string,
    link: PropTypes.object,
  }),
};

export default React.memo(Button);